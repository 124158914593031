export default function Chat({ chat, typing }) {
  let location = chat.location;
  let name = chat.name;
  let message = chat.chat;

  return (
    <div className={`msg ${location}-msg`}>
      <div
        className="msg-img"
        style={{
          backgroudImage: "url('http://localhost:3000/caravea-logo.png')",
        }}
      ></div>

      <div className="msg-bubble">
        <div className="msg-info">
          <div className="msg-info-name">{name}</div>
          <div className="msg-info-time"></div>
        </div>

        <div
          className="msg-text"
          dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, "<br />") }}
        ></div>
      </div>
    </div>
  );
}
