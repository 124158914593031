import { useEffect, useRef } from "react";
import Chat from "./Chat";

export default function ChatContainer({ chats, typing }) {
  const messagesEndRef = useRef(null);
  useEffect(() => {
    scrollToBottom();
  }, [chats]);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const chatData = {
    name: "Caravea",
    chat: "<div class='typing'><span></span><span></span><span></span></div>",
    date: Date.now(),
    location: "left",
  };

  return (
    <div className="msger-chat">
      {chats.map((chat) => (
        <Chat chat={chat} typing={typing} key={chat.date} />
      ))}
      {typing ? <Chat chat={chatData} typing={typing} key={[]} /> : null}
      <div ref={messagesEndRef} />
    </div>
  );
}
