import { useState } from "react";
import Header from "./Header";
import ChatContainer from "./ChatContainer";
import ChatForm from "./ChatForm";
import Footer from "./Footer";

export default function App() {
  const [typing, setTyping] = useState(false);
  const [chats, setChats] = useState([
    {
      name: "Caravea",
      chat: "Hi team, how can I assist you?",
      date: Date.now(),
      location: "left",
    },
  ]);
  function handleAddChats(chat) {
    setChats((chats) => [...chats, chat]);
  }
  function handleTyping(typing) {
    setTyping(typing);
  }
  return (
    <div className="msger">
      <Header />
      <ChatContainer chats={chats} typing={typing} />
      <ChatForm
        onAddChats={handleAddChats}
        onTyping={handleTyping}
        typing={typing}
      />
      <Footer />
    </div>
  );
}
