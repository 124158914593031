import { useState } from "react";

export default function ChatForm({ onAddChats, onTyping, typing }) {
  const [chat, setChat] = useState("");

  // const apiKey = "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";

  const handleSubmit = async (e) => {
    e.preventDefault();
    onTyping(true);
    onAddChats({
      name: "",
      chat,
      date: Date.now(),
      location: "right",
    });

    setChat("");

    try {
      const apiUrl =
        "http://20.211.87.210:8888/cb/create/?user_inquiry=" + chat;
      const response = await fetch(apiUrl, {
        method: "GET",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YWRtaW46YWRtaW4=",
        },
      });
      const result = await response.json();
      console.log(result);

      onAddChats({
        name: "Caravea",
        chat: result.chatbot_answer,
        date: Date.now(),
        location: "left",
      });
    } catch (error) {
      console.log(error);
      onAddChats({
        name: "Caravea",
        chat: "Failed to fetch response. Please try again later.",
        date: Date.now(),
        location: "left",
      });
    }

    // try {
    //   const result = await fetch("https://api.openai.com/v1/chat/completions", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${apiKey}`,
    //     },
    //     body: JSON.stringify({
    //       model: "gpt-3.5-turbo",
    //       messages: [{ role: "user", content: chat }],
    //     }),
    //   });
    //   const data = await result.json();
    //   onAddChats({
    //     name: "Caravea",
    //     chat: data.choices[0].message.content,
    //     date: Date.now(),
    //     location: "left",
    //   });
    // } catch (error) {
    //   console.error("Error:", error);
    //   onAddChats({
    //     name: "Caravea",
    //     chat: "Failed to fetch response. Please try again later.",
    //     date: Date.now(),
    //     location: "left",
    //   });
    // }
    onTyping(false);
  };

  return (
    <form className="msger-inputarea" onSubmit={handleSubmit}>
      <input
        className="msger-input"
        type="text"
        placeholder="Enter your message..."
        value={chat}
        onChange={(e) => setChat(e.target.value)}
        disabled={typing && "disabled"}
      />
      <button
        type="submit"
        className="msger-send-btn"
        disabled={typing && "disabled"}
      >
        Send
      </button>
    </form>
  );
}
